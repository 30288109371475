import React,{  useEffect, useMemo, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCrop, faImage, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";


import ReactDOM from 'react-dom';

import { retrieveProject } from "../../service/mftc-api";
import VarContent from "../VarContent";


const ChooseImage = ({value,label, onChange,className,domain,placeholder,defaultValue,immersiveMode,context}) => {


const positionRef = useRef(null)
const id = "ChooseImage" +label ;

const  project= retrieveProject();
const [show,setShow]=useState()
const [editUrl,setEditUrl]=useState()
const [images,setImages   ]= useState([]);
const [showPosition,setShowPosition]=useState()

const [position, setPosition] = useState(null);
const [isDragging, setIsDragging] = useState(false);
const [dragStart, setDragStart] = useState();
const [resizeMode, setResizeMode] = useState(null); // 'move', 'nw', 'ne', 'sw', 'se', null

const [infos,setInfos]=useState({})





var drg =null
const handleDragStart = (e) => {
e.preventDefault()
e.stopPropagation()

  if(drg || dragStart) return
drg=true


  const rect = document.getElementById("rectangle").getBoundingClientRect();
  const x = 100*(e.clientX-rect.left)/rect.width;
  const y = 100*(e.clientY-rect.top)/rect.height;

  const _x = (x-position.x)/position.w
  const _y = (y-position.y)/position.h
  const borderSize = 0.33; // Zone de détection pour le redimensionnement
var dx =""
var dy =""
  // Déterminer le mode de redimensionnement
  if (_x < borderSize) dx="w"
  else if (_x > 1 - borderSize) dx="e"

  if (_y < borderSize) dy="n"
  else if (_y > 1 - borderSize) dy="s"

setResizeMode(dy+dx)


  setIsDragging(true);
  setDragStart({
    x:  100*(e.clientX-rect.left)/rect.width ,
    y:  100*(e.clientY-rect.top)/rect.height,
    startX:position.x,
    startY:position.y,
    startW:position.w,
    startH:position.h
  });
};

// Fonction pour gérer le drag
const handleDrag = (e) => {
  e.preventDefault()
e.stopPropagation()
  if (!dragStart) return;

  const rect = document.getElementById("rectangle").getBoundingClientRect();
  const deltaX = (100*(e.clientX-rect.left)/rect.width - dragStart.x);
  const deltaY = (100*(e.clientY-rect.top)/rect.height - dragStart.y);
setInfos({id: e.target.id, deltaX,deltaY,clientX:e.clientX,clientY:e.clientY,rect})



  switch (resizeMode) {
    case '':
      const newX = Math.max(0, Math.min( dragStart.startX+ deltaX,90 ));
      const newY = Math.max(0, Math.min(dragStart.startY+ deltaY, 90));
      const newW =  Math.min(100 - newX, dragStart.startW);
      const newH = Math.min(100 - newY, dragStart.startH );

      setPosition({
        ...position,
        x: newX,
        y: newY,
        w: newW,
        h: newH
      });
      break;
      case 'w':
      setPosition({
        ...position,
        x: Math.max(0, Math.min(deltaX+dragStart.startX, 90)),
      
        w: Math.max(10, dragStart.startW-deltaX ),
     
      });
      break;    
    case 'nw':
      setPosition({
        x: Math.max(0, Math.min(deltaX+dragStart.startX, 90)),
        y: Math.max(0, Math.min(deltaY+dragStart.startY,90)),
        w: Math.max(10, dragStart.startW - deltaX),
        h: Math.max(10, dragStart.startH - deltaY)
      });
      break;
      case 'n':
        setPosition({
       ...position,
          y: Math.max(0, Math.min(deltaY+dragStart.startY,90)),
       
          h: Math.max(10, dragStart.startH - deltaY)
        });
        break;
        case 'e':
          setPosition({
            ...position,
          
            w: Math.max(10, Math.min(100 - dragStart.startX, deltaX+dragStart.startW)),
          
          });
          break;
    case 'ne':
      setPosition({
        ...position,
        y: Math.max(0, Math.min(deltaY+dragStart.startY, 90)),
        w: Math.max(10, Math.min(100 - dragStart.startX, deltaX+dragStart.startW)),
        h: Math.max(10, dragStart.startH - deltaY)
      });
      break;
    // ... similar cases for 'sw' and 'se' ...
    case 's':
      setPosition({
        ...position,
    
        h: Math.max(10, Math.min(100 - dragStart.startY, deltaY+dragStart.startH)),
      });
      break;
    case 'sw':
      setPosition({
        ...position,
        x: Math.max(0, Math.min(deltaX+dragStart.startX, 90)),
       
        w: Math.max(10, dragStart.startW - deltaX),

        h: Math.max(10, Math.min(100 - dragStart.startY, deltaY+dragStart.startH)),
      });
      break;
    case 'se':
      setPosition({
        ...position,
        w: Math.max(10, Math.min(100 - dragStart.startX, deltaX+dragStart.startW)),
        h: Math.max(10, Math.min(100 - dragStart.startY, deltaY+dragStart.startH)),
      });
      break;
      default:
        break;
  }

};

// Fonction pour arrêter le drag
const handleDragEnd = () => {
  setIsDragging(false);
  drg=null
  setResizeMode(null);
  setDragStart()
  var newValue = `${value.split("?")[0]}${ position  && JSON.stringify(position)!==JSON.stringify({x:0,y:0,w:100,h:100}) ? `?pos=${Math.round(position.x)},${Math.round(position.y)},${Math.round(position.w)},${Math.round(position.h)}` : ""}`
  if(newValue!==value) onChange(newValue);
};


useEffect(()=>{ 

const _pos= (value+"")?.split("pos=")
if(_pos.length===2)  {
 var pos  = _pos[1].split(",")
setPosition({
  x:(pos[0]!=="NaN" ?parseInt(pos[0]) : 25)??0,
  y:(pos[1]!=="NaN" ? parseInt(pos[1]) :25)??0,
  w:(pos[2]!=="NaN" ? parseInt(pos[2]) : 50)??100,
  h:(pos[3]!=="NaN" ? parseInt(pos[3]) : 50)??100
})

 }
 else setPosition({x:0,y:0,w:100,h:100})

 },[value])



const getImages=async ()=>{

  var _images = await project.getFiles(domain ? "apps/" + domain : ""  );

  setImages(_images);
}


useEffect(()=>{


  if(show  )  getImages()
},[show,domain,getImages])
    



function onUploadProgress(progressEvent){
    const { loaded, total } = progressEvent;
    let precentage = Math.floor((loaded * 100) / total);
//    setProgression({precentage,loaded, total})
    console.log(precentage);

  if( precentage===100)
  {
   getImages();
  }
}

const onSelect=(image)=>{

    onChange(image);
    setShow(false)
};

  function handleImgChange(e) {
      console.log(e.target.files);
      if(e.target.files.length===0) return 
      setImages([URL.createObjectURL(e.target.files[0]),...images]);

 project.uploadFile( e.target.files, onUploadProgress, domain ? "apps/" + domain : "" ).then( results=>{

  if( results && results.length===1){
    console.log( results[0]);

  }
 })


}
if((typeof value)==="object") value=null
var labele = value &&  (value).split("/").pop()
if(labele && labele.length===0) labele= null
console.log("Value",typeof value)
return  <>


{
immersiveMode ? 

<div className={className +" overflow-hidden" } onClick={()=>setShow(true)} >
{(defaultValue || value)  && <img  src={value ?? defaultValue}  className=" w-full h-full object-cover" alt="im"  /> }
</div>
:
<div className="flex flex-col w-full">
<label htmlFor={ "input_"+label } className="text-left w-4 text-sm text-gray-500 z-40 duration-300 transform translate-y-2 scale-75 top-2  origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
<div className={" flex flex-row justify-between items-center relative border-zinc-400 rounded-xl border p-2 overflow-hidden " + className} >

    {(defaultValue || value)  && <>  <img  src={value ?? defaultValue + ( position  && (position.w!==100 || position.h!==100) ? "&nocache=true":"")}  className=" w-16 h-16 object-cover " 
    
    alt="im"  onClick={()=>setShow(true)} /> 
</> }
    <div className="flex w-full">
    <div onClick={()=>setShow(true)} className="p-2 m-1" > { false && (labele ?? placeholder) }<FontAwesomeIcon icon={faImage}/></div>
    <div onClick={()=>setEditUrl(!editUrl)} className="p-2 m-1" > { false && (labele ?? placeholder) }<FontAwesomeIcon icon={faLink}/> </div>
    {editUrl &&
    <input type="url" className=" ml-2 w-full border-b border-gray-600" placeholder="saisir url"   value={value}   onChange={(e)=>{ onChange(e.target.value)   }}   />
    }
    {value && <FontAwesomeIcon className="appbutton p-2 m-1" icon={faCrop} onClick={()=>setShowPosition(true)} /> }
    </div>
 { value ? <FontAwesomeIcon className=" text-red-600 p-2" icon={faClose}  onClick={()=>onChange(null)   }  /> :
 <FontAwesomeIcon className="opacity-0 p-2" icon={faClose}     />    }
</div></div>}
{show &&  
 ReactDOM.createPortal(    <div id={id } className="  fixed h-screen w-screen p-4 top-0 left-0 bg-black bg-opacity-90 text-2xl font-bold flex flex-col justify-start items-center  overflow-auto" style={{zIndex:500000}} >
       <div className="w-full p-4 text-white flex flex-row justify-between "> <span> Choisir une image {label} - {domain}</span>   <FontAwesomeIcon className="appbutton" onClick={()=>setShow(false)} icon={faClose } ></FontAwesomeIcon> </div>
        <div className="w-full  flex flex-wrap  p-4 ">
    
 <div   onClick={ ()=>{ document.getElementById("image-upload-" + label ).click()}}  className=" m-2 w-20 h-20 rounded-xl bg-white text-2xl "><FontAwesomeIcon icon={faPlusCircle} ></FontAwesomeIcon> </div>
 <input type="file" accept={"video/mp4,audio/mp3,image/png,image/jpeg"} name="image" id={"image-upload-"+label} className="hidden" onChange={handleImgChange} required />

{
    images.map((image,i)=><img src={image}  key={i}  className={"   max-w-fit  h-20 object-cover bg-black m-2 " + (image.includes("https:") ? "":" opacity-50 border border-white border-dashed blur-md")    } alt={image} onClick={()=>{ if (image.includes("https:"))onSelect(image)}} />)
}

        </div>
        
</div> ,   document.getElementById('root'))
}
{showPosition &&  
 ReactDOM.createPortal(    <div   ref={positionRef}  
  onClick={(e) => e.stopPropagation()}  id={"position-"+ id } className="z-50   p-8 fixed h-screen w-screen top-0 left-0 bg-black bg-opacity-90 text-2xl font-bold flex flex-col justify-start items-center  overflow-auto" style={{zIndex:500000}} >
       <div className="w-full p-4 text-white flex flex-row justify-between "> <span> ReCadrage</span>   <FontAwesomeIcon className="appbutton" onClick={()=>setShowPosition(false)} icon={faClose } ></FontAwesomeIcon> </div>
        <div id="rectangle" className="relative flex flex-wrap bg-white"
             
             onMouseDown={handleDragStart}
             onMouseMove={handleDrag}
             onMouseUp={handleDragEnd}
             onMouseLeave={handleDragEnd}
        >
    
        <img 
        id="image-position"
      src={ (value ?? defaultValue).split("?")[0] }  
      className="object-contain max-w-full max-h-[80vh] " 
      alt="im"  
      onClick={() => setShow(true)} 
   
    />
    {position && (
      <div 
        className={`absolute border-2 border-blue-500 ${
          isDragging ? '' : 'cursor-' + (resizeMode || 'move')
        }`}
        style={{
          left: `${position.x}%`,
          top: `${position.y}%`,
          width: `${position.w}%`,
          height: `${position.h}%`,
          cursor: resizeMode === 'nw' || resizeMode === 'se' ? 'nwse-resize' :
                 resizeMode === 'ne' || resizeMode === 'sw' ? 'nesw-resize' :
                 resizeMode === 'move' ? 'move' : 'default'
        }}

      />
    )}

        </div>

</div> ,   document.getElementById('root'))
}
</>

}
export default ChooseImage