import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  version,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import TemplateX from "../../../../components/templates/TemplateX";
import {
  _GetSetTers,
  arrayMerge,
  contentConvert,
  createClass,
  getConditions,
  getIdStructure,
  getProps,
  getPropsFromTemplates,
  getStructure,
  getSuidTemplates,
  overrideContent,
  setPropInputs,
  updateStructure,
} from "../../../../tools/tpl";

import * as fa from "@fortawesome/free-solid-svg-icons";
import { captureContent } from "../../../../service/background";

import "../../../../App.css";
import { MfctContext } from "../../../../context";

import UserPin from "../../../../components/UserPin";

import { data } from "../../../../service/data";

import TemplateTree from "./TemplateTree";
import TemplateParams from "./sideBars/TemplateParams";
import ShowTagStructure from "./sideBars/ShowTagStructure";
import ShowTemplateStructure from "./sideBars/ShowTemplateStructure";
import ShowTemplateEvent from "./sideBars/ShowTemplateEvent";
import ShowComponentTools from "./sideBars/ShowComponentTools";
import ShowComponentAddTools from "./sideBars/ShowComponentAddTools";
import ShowTestingContent from "./sideBars/ShowTestingContent";

import ButtonTool from "../../../../components/ButtonTool";
import { ModalPositions, showModal } from "../../../../components/modal";
import {
  getActions,
  loadAction,
  loadActions,
  runAction,
  runActionSync,
} from "../../../../tools/actions";
import ShowTemplateProps from "./sideBars/ShowTemplateProps";
import ShowLocalContent from "./sideBars/ShowLocalContent";
import { addWorkingStone } from "../../../../service/milestone";
import ShowDataSource from "./sideBars/ShowDataSource";
import { loadAppFonts, loadFontAsync } from "../../../../tools/fonts";
import InputSelect from "../../../../components/inputs/InputSelect";
import TemplateProps from "./sideBars/TemplateProps";
import useWhyDidYouUpdate from "../../../../tools/useWhyDidYouUpdate";

import AppThumb from "../../../../components/thumbs/AppThumb";
import TemplateMeta from "./sideBars/TemplateMeta";
import { suidize } from "../../../../service/suid";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import SelectedBound from "./Frame";
import Frame from "./Frame";
import ContextToolManager from "./contextTools/Context_ToolManager";
import ShowTemplateEventActions from "./sideBars/ShowTemplateEventActions";
import { initCssGlobalTemplate, setStructureCss, setTemplateCss } from "../../../../tools/mediacss";
var userActives = [];
//var globaltestContent;






function SetTemplate() {

useWhyDidYouUpdate("SetTemplate")

  const {
    project,

    authUser,

    cache,
   
  } = useContext(MfctContext);
  const { template_id } = useParams();

  const [simulatingScroll,setSimulatingScroll]= useState({perc:0})
  const session_id = "S" + authUser.user_id;
  const [dragged, setDragged] = useState();
  const [dragOverZone, setDragOverZone] = useState();
  const [templateVersions, setTemplateVersions] = useState();
  const [localContent, _setLocalContent] = useState({});
  const setLocalContent = (c) => {
    if (
      show.localContent &&
      JSON.stringify(localContent) !== JSON.stringify({ selected, ...c })
    ) {
      _setLocalContent({ selected, ...c });
      console.log({ selected, localContent });
    }
  };

  const [draggedZone, setDraggedZone] = useState();

  const randomColor = () => {
    // Générer trois composants de couleur RGB aléatoires
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Convertir les composants RGB en chaîne hexadécimale et les concaténer
    const couleurHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return couleurHex;
  };
  const [users, setUsers] = useState([
    {
      session_id,
      imageUrl: authUser.profile.imageUrl,
      bgColor: randomColor(),
    },
  ]);

  const [baseContent, setBaseContent] = useState({
    "App.Name": "...",
  });
  const [page, setPage] = useState();

  const [app, setApp] = useState();

  const [explode, setExplode] = useState();

  const [contents, setContents] = useState([]);

  const setCompleteContents = setContents;
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState();
  const [selectedBloc, setSelectedBloc] = useState();
  const [selected, setSelected] = useState();
  const [schemas, setSchemas] = useState([]);
const [suidTemplates,setSuidTemplates]= useState({})
  const [selectedTemplate, setSelectedTemplate] = useState();

  const [saveAll, setSaveAll] = useState({});
  const [loading, setLoading] = useState(false);
  const [internalContent, setInternalContent] = useState({});
  const [isMod, setIsMod] = useState();
  const [show, setShow] = useState({ showHidden: false ,showScroll:true});
  const [showTree, setShowTree] = useState();
  const [showProps, setShowProps] = useState();
  const [roles, setRoles] = useState([]);
  const [scale, setScale] = useState(0.9);

  const [ComponentAddTools, setComponentAddTools] = useState({});
  const [ComponentStructTools, setComponentStructTools] = useState({});
  const [ComponentTools, setComponentTools] = useState({});
  const setInternal = (key, value) => {
    setInternalContent((_content) => ({ ..._content, [key]: value }));
  };
  const loadTools = async () => {
    const addtoolfiles = require.context("./addTools", false, /\.js?$/); // Récupère tous les fichiers .js ou .jsx du répertoire addTools
    const addcomponents = {};

    addtoolfiles.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      addcomponents[componentName] = addtoolfiles(key).default;
    });

    setComponentAddTools(addcomponents);

    const toolfiles = require.context("./tools", false, /\.js?$/); // Récupère tous les fichiers .js ou .jsx du répertoire tools
    const toolcomponents = {};

    toolfiles.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      toolcomponents[componentName] = toolfiles(key).default;
    });

    setComponentTools(toolcomponents);

    const structToolfiles = require.context("./structTools", false, /\.js?$/); // Récupère tous les fichiers .js ou .jsx du répertoire tools
    const structToolcomponents = {};

    structToolfiles.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      structToolcomponents[componentName] = structToolfiles(key).default;
    });

    setComponentStructTools(structToolcomponents);
  };

  useEffect(() => {
    loadTools();
  }, []);




  const getRoles = async (app_id) => {
    var _roles = await project.api("/get_myroles/:app_id", {
      args: { app_id },
    });
    console.log(_roles);
    setRoles(_roles);
  };

  const cancelling = async () => {
    setTemplateVersions();
    sessionStorage.removeItem("templateVersions_" + template_id);

    data.cancelPage(page);
  };

  const undoing = async () => {

if(!templateVersions || templateVersions.length ===0) return


    var versions = templateVersions;
    versions.pop();
    var newtemplate = versions[versions.length - 1];

    data.updateTemplate(newtemplate);
  };

  const saving = async () => {
    if(!templateVersions || templateVersions.length ===0) return
    setLoading(true);
    sessionStorage.removeItem("templateVersions_" + template_id);
    await data.saveAll();
    setLoading(false);

   setTimeout(captureTemplate, 100);
  };

  const [propsTemplates, setPropsTemplates] = useState();



const [templateToGrab , setTemplateToGrab] = useState();

  const [selectingChoose,setSelectingChoose] = useState();



  const positionToGrab= (event)=>{
      const element = event.target;
      const rect = element.getBoundingClientRect();
  element.style.backgroundColor ="#34F545"
      // Coordonnées du centre de l'élément

  
      // Coordonnées du clic
      const clickX = event.clientX;
      const clickY = event.clientY;
  
      // Calcul des distances entre la souris et chaque bord
      const distanceToTop = clickY - rect.top;
      const distanceToBottom = rect.bottom - clickY;
      const distanceToLeft = clickX - rect.left;
      const distanceToRight = rect.right - clickX;
  
      // Calcul des distances entre le centre et chaque bord
      const distanceToptoBottom = rect.bottom - rect.top;


      const distanceRightToLeft = rect.right - rect.left;
  
      // Calcul des pourcentages
      const bottomPercentage  = (distanceToTop / distanceToptoBottom) * 100;
      const topPercentage = (distanceToBottom / distanceToptoBottom) * 100;
      const rightPercentage  = (distanceToLeft / distanceRightToLeft) * 100;
      const leftPercentage = (distanceToRight / distanceRightToLeft) * 100;
  
      // Mise à jour de l'état avec les nouveaux pourcentages
      var er= {
        id: element.id,
        topPercentage: Math.min(topPercentage, 100), // Limiter à 100%
        bottomPercentage: Math.min(bottomPercentage, 100),
        leftPercentage: Math.min(leftPercentage, 100),
        rightPercentage: Math.min(rightPercentage, 100),
      }


      // Calculer les distances de chaque enfant
    const childrenDistances = Array.from(element.children).map((child) => {
      const childRect = child.getBoundingClientRect();
      return {
        id: child.id,
        distances: {
          top: Math.max(0,  childRect.top-clickY),
          bottom: Math.max(0,clickY - childRect.bottom),
          left:Math.max(0, childRect.left - clickX),
          right:Math.max(0,clickX -  childRect.right) ,
        },
      };
    });

    // Trier les enfants en fonction de la proximité avec la souris (somme des distances)
    const sortedChildren = childrenDistances.sort((a, b) => {
      const distanceA = 
        Math.abs(a.distances.top) +
        Math.abs(a.distances.bottom) +
        Math.abs(a.distances.left) +
        Math.abs(a.distances.right);
      const distanceB =
        Math.abs(b.distances.top) +
        Math.abs(b.distances.bottom) +
        Math.abs(b.distances.left) +
        Math.abs(b.distances.right);
      return distanceA - distanceB;
    });

    // Prendre les deux enfants les plus proches
    er.closestChildren = sortedChildren.slice(0, 2).map((childData) => ({
      id: childData.id,
      distances: childData.distances,
    }));




      console.log(er);
window.alert(JSON.stringify(er))
  
  }
const ScreenSizes =[
  {deviceName: "iPhoneSE",mq:"sm", style: {width: "375px", minHeight: "667px"}},
  {deviceName: "iPhone 13/14",mq:"sm", style: {width: "390px", minHeight: "844px"}},

  {deviceName: "Samsung Galaxy S23",mq:"sm", style: {width: "412px", minHeight: "915px"}},
  {deviceName: "Google Pixel 7",mq:"sm", style: {width: "412px", minHeight: "915px"}},
  {deviceName: "Google Pixel 6", mq:"sm",style: {width: "411px", minHeight: "823px"}},
  {deviceName: "OnePlus 10 Pro", mq:"sm",style: {width: "412px", minHeight: "920px"}},

  {deviceName: "iPad Air", mq:"md",style: {width: "820px", minHeight: "1180px"}},
  {deviceName: "Surface Pro 9",mq:"md", style: {width: "912px", minHeight: "1368px"}},
  {deviceName: "iPad Pro 12.9\"",mq:"lg", style: {width: "1024px", minHeight: "1366px"}},
  

  {deviceName: "MacBook Air M2",mq:"xl", style: {width: "1280px", minHeight: "832px"}},
  {deviceName: "iMac 24\"", mq:"xl",style: {width: "1280px", minHeight: "720px"}},
  {deviceName: "1366x768 Desktop",mq:"xl", style: {width: "1366px", minHeight: "768px"}},
  {deviceName: "MacBook Pro 16\"",mq:"2xl", style: {width: "1536px", minHeight: "960px"}},

  {deviceName: "Surface Laptop 4", mq:"2xl", style: {width: "1536px", minHeight: "1024px"}},
  {deviceName: "1920x1080 Desktop", mq:"2xl", style: {width: "1920px", minHeight: "1080px"}},
  {deviceName: "Dell XPS 13",mq:"2xl",  style: {width: "1920px", minHeight: "1200px"}},


  {deviceName: "2560x1440 Desktop", style: {width: "2560px", minHeight: "1440px"}}
]
const mediaQueries = [
  { name:"mobile",  prefixe: 'sm', width: '640px', height: 'none' },  // Small (min-width: 640px)
  { prefixe: 'md', width: '768px', height: 'none' },  // Medium (min-width: 768px)
  { prefixe: 'lg', width: '1024px', height: 'none' }, // Large (min-width: 1024px)
  { prefixe: 'xl', width: '1280px', height: 'none' }, // Extra Large (min-width: 1280px)
  { prefixe: '2xl', width: '1536px', height: 'none' } // 2X Large (min-width: 1536px)
];


var sc = localStorage.getItem("ScreenSize")
if(sc) sc= JSON.parse(sc)
const [ScreenSize,setScreenSize ] = useState( sc   ??{ width:"390px",minHeight:"844px"})

const cleanResponsiveClassName = (className)=> {
 if(!className) return className+""
var width = parseInt(  ScreenSize.width)
var _className = className +""
for( var i=mediaQueries.length - 1; i >= 0; i--){
  var minWidth = parseInt(  mediaQueries[i].width)

  if(width<minWidth) {
 //   console.log(  "/// On enleve les classes qui commencent par  [prefixe]: " + mediaQueries[i].prefixe+":"  )  /// On enleve les classes qui commencent par  [prefixe]:
      _className =
      _className.split( mediaQueries[i].prefixe+":").join("off:")
  }
}

_className =_className.split("w-screen").join("width="+ ScreenSize.width)
_className =_className.split("h-screen").join("height="+ ScreenSize.minHeight)
if(_className!==className){
 // console.log("cleanResponsiveClassName ", minWidth,width,{className ,_className})
}
return _className;
}

const changeDevice= (deviceName)=>{

var s = ScreenSizes.find(s => s.deviceName === deviceName);
setScreenSize( s.style   )
localStorage.setItem("ScreenSize",JSON.stringify(s.style))

}

const [mediaQuery,setMediaQuery]=useState()

const changeMediaQuery= (mq)=>{
if(mq ===mediaQuery) mq=null
  setMediaQuery(mq)
if(!mq) return
  var s = ScreenSizes.find(s => s.mq === mq);
  setScreenSize( s.style   )
  localStorage.setItem("ScreenSize",JSON.stringify(s.style))
  
  }


  const key_schemas = useMemo(() => {
    var result = {};
    if (template) {
    var propsFromTemplates= template.props_from_templates

      if (template.schema_id)
        result[
          template.schema_id + (template.layout === "list" ? "_list" : "")
        ] = ["self"];

      if (propsFromTemplates && propsFromTemplates[template.template_id]) {
        for (let prop of Object.values(
          propsFromTemplates[template.template_id].props
        )) {
          if (!prop.schema_id) continue;
          var s =
            prop.schema_id +
            (prop.type_nombre === "I" || prop.type_nombre === "F"
              ? "_list"
              : "");
          result[s] = arrayMerge(result[s] ?? [], [prop.name]);
        }
      }
      if (template.props) {
        for (let prop of (Array.isArray( template.props) ? template.props : [])) {
          if (!prop.schema_id) continue;
          var s =
            prop.schema_id +
            (prop.type_nombre === "I" || prop.type_nombre === "F"
              ? "_list"
              : "");
          result[s] = arrayMerge(result[s] ?? [], [prop.name]);
        }
      }

      var parts = (   (selected + "").replace("-root.","") ).split(".");
      var structure = template.structure;
      console.log("STARTING  key_schemas "  + parts.join(".") , { structure } );
      while (structure) {
        let label = structure.schema_prop ?? structure.schema_label;

        var index = parts.shift();
        if(!index) break;
        var intIndex = parseInt(index.split(":"));
        console.log(" key_schemas "  + parts.join(".") , {label,intIndex, structure , result} );

        

        if (structure.schema_id && label) {
          var key = structure.schema_id + (structure.isList ? "_list" : "");

          if (structure.isList &&   !isNaN( intIndex)) {    // && !structure.template_id
            key = structure.schema_id + "";

            result[key] = arrayMerge(result[key], [label]);
            result[key+"_list"]=  (result[key+"_list"] ?? []).filter(c=>  c!== label)
            console.log(selected + " ADD  ->  key_schemas", result);

          } else {
            result[key] = arrayMerge(result[key], [label]);
            console.log(selected + " ADD  ->  key_schemas", result);
          }
        }

        if (  !isNaN( intIndex) && structure.children[intIndex]) {
          structure = { ...structure.children[intIndex] };
        } else structure = null;
      }

      console.log(selected + " key_schemas !!! ", {
        template,
        propsFromTemplates,
        result,
      });
    }

    return result;
  }, [template, selected]);

  const convertContent = useMemo(() => {
    return []
  
  }, []);





const templateContent = useMemo(()=>{ 

  return overrideContent ( contents?.find(c=>c.content_id===template?.default_content_id),suidTemplates  )


},[contents,template?.default_content_id,suidTemplates])

const changePropContent= async(prop_name,value)=>{
  var tc=  template?.default_content_id && contents?.find(c=>c.content_id===template?.default_content_id);
  tc={...tc,content: { ...tc.content,[prop_name] :value}}

     await updateTestContent(tc, schemas )
}

  const updateTestContent = async (tc, _schemas) => {
   // globaltestContent = tc;
   var _tc= template?.default_content_id && contents?.find(c=>c.content_id===template?.default_content_id);
   if(!tc)  return;

    console.log("vidange x", { _tc, tc });
    if(tc?.content_id   && JSON.stringify(tc)!== JSON.stringify(_tc) )  {
    
   await data.updateContent(tc);
    await data.getCompleteContents([tc.content_id], _schemas);
    }
  };



  const GetActions =(template,templates) => {
    var values = [];
    if (template && templates.length > 0)
     {
       values = getActions(template, templates);
     }
    return values;
  }


  const GetSetTers = (_template, getSelf, setSelf, infos) => {

    if(!template) return {}
    
    console.log( " **** GetSetTers" +  _template.title + " template?.props_from_templates : ",  {templateContent, props:template?.props_from_templates})
    if(infos?.element_id)
      console.log( infos?.element_id +" **** GetSetTers" +  _template.title + " template?.props_from_templates : ",  template?.props_from_templates)
    return {
      ..._GetSetTers(
        _template,
        getSelf,
        setSelf,
     template.default_content_id ??  "content_template_test_" + template_id,
       [ templateContent, ...contents],
     template?.props_from_templates,
        schemas,
        infos
      ),
      GetSetTers,
    };
  };

  const GetSetTersProps = (_template, getSelf, setSelf, infos) => {
    if(!template) return {}
if(infos?.element_id)
    console.log( infos?.element_id +" **** GetSetTersProps" +  _template.title + " template?.props_from_templates : ",  template?.props_from_templates)

    return {
      ..._GetSetTers(
        _template,
        getSelf,
        setSelf,
        template.default_content_id ??  "content_template_test_" + template_id,
        [ templateContent, ...contents],
        _template?.props_from_templates ,
        schemas,
        infos
      ),
      GetSetTers: GetSetTersProps,
    };
  };


    const computeProps = async (template,templates,schemas) => {
      if (!template) return;

      var _propsFromTemplates = await getPropsFromTemplates(
        template.template_id,
        cache,
      [template,...templates],
        project
      );
      if(!Array.isArray (template.props)) template.props=[]
      var props =( template.props?? []).filter(p=> !["schema_label","schema_link","schema_url","schema_click","schema_suid"].includes(p.from) );
      for (let prop of Object.values(
        _propsFromTemplates[template.template_id].props
      )) {

        if (prop.computed) {
          console.log("Loading Action" + prop.name, prop.computed);
          await loadAction(prop.computed);
        }
   
        if (["schema_label","schema_link","schema_url","schema_click","schema_suid"].includes(prop.from)    && !props.find(p=>p.name ===prop.name) ){
          props.push({...prop,type_nombre: prop.type_nombre ?? ( prop.isList? "I":null)})

        }

      }
      template.props =props
        if(template.props && Array.isArray(template.props ))
           template.props.forEach(prop=>{
          _propsFromTemplates[template.template_id].props[prop.name]= {..._propsFromTemplates[template.template_id].props[prop.name]??{} , ...prop,from:"schema_label"}
        })
    


        _propsFromTemplates[template.template_id].props["Titre"]= {"name":"Titre",schema_id:42 ,from:"self"}
        _propsFromTemplates[template.template_id].props["title"]= {"name":"title",schema_id:42 ,from:"self"}


        console.log("Calul des props_from_templates  !!!",_propsFromTemplates)
     template.props_from_templates =    _propsFromTemplates;
    
      var _propsTemplates = [];
      _propsTemplates = await getProps(
        template.structure,
        cache,
        [template,...templates],
        project,
        template.template_id
      );

      console.log("Recalculating props...", _propsTemplates);
      if (template.schema_id) {
        var sch = schemas.find(
          (s) => s.content_id + "" === "" + template.schema_id
        );
        if (sch) {
          if (sch.content.is_value)
            _propsTemplates = arrayMerge(
              _propsTemplates,
              [
                {
                  name: "self",
                  self: true,
                  schema_id: template.schema_id,
                  type_nombre:template.layout==="list" ? "I":""
                },
              ],
              "name",
              true
            );
          else
            _propsTemplates = arrayMerge(
              _propsTemplates,
              [
                {
                  name: "self",
                  self: true,
                  schema_id: template.schema_id,
                  type_nombre:template.layout==="list" ? "I":""
                },
              ],
              "name",
              true
            );
        }
      }

      console.log("_propsTemplates", schemas, _propsTemplates);
      if (JSON.stringify(_propsTemplates) !== JSON.stringify(propsTemplates))
        template.propsTemplates = _propsTemplates;


      return template
    };

   const [domain,setDomain]=useState()

  useEffect(() => {
    const dataInit = async () => {
      console.log("dataInit ", template_id);
      await data.init(
        "template",
        template_id,
        true,
        project,
        {
          setApp,
          setTemplates,
          setTemplate,
          setContents,
          setUser,
          setSchemas,
          setSaveAll,
          setCompleteContents,
        },
        { session_id: "S" + authUser.user_id },
        true
      );

      // Recuperer la page *
if(template && template.template_id+""===""+template_id)return ;
      let _template = await data.getTemplate(template_id,true,true);
console.log("Template :" ,{_template} )
      if (!_template) navigate("/404");

      // Recuperer l'app
      document.title = "✎TPL " + _template.title;
      // Recuperer les schemas
     
     
var _schemas = await data.getSchemas();
var _templates = await data.getTemplates();
_template = await migrateTemplate(_template,_templates)
await   addWorkingStone( _template,"Edition" ,"template"  )   
  await  computeProps(_template, _templates, _schemas );
     
  initCssGlobalTemplate(_template,_templates )

setPropsTemplatesFrom(
        Object.values(  _template.props_from_templates[_template.template_id].props ).filter(p=> p.from!=="self"))
      console.log("dataInit ok", _template);
    await   getAppData(_template);
      setTemplate(_template)

    };

    dataInit();
    return async () => {
   
      await data.close();
    };
  }, [template_id]);

  var app_id;

useEffect(()=>{



if(template?.layout)
{
  setShow((show) => ({
    ...show,
    showScroll: template?.layout==="bloc" || template?.layout==="top" || template?.layout==="bottom",
  }));
}

},[template?.layout])
 useEffect(()=>{

  if(template?.app_id) loadAppFonts(template.app_id)


},[template])


  const getAppData = async (template) => {
    var _schemas;
    let _app 


if(!template) return 

   
var _templates = await data.getTemplates();

    if (template.app_id) {
  _app   = await data.getApp(template.app_id);

      await getRoles(_app.app_id);
      _schemas = await data.getSchemasByApp(_app.app_id);


    setDomain(  _app.domain)
  //    globaltestContent = ;
      
      await initApp(template,_app)
    } else {
      _schemas = await data.getSchemas();

      


    } // Recuperer les templates


    var _templateContent
    
    if(template?.default_content_id)
      {
        _templateContent = await data.getContent(template.default_content_id);
      }

      await loadAndRunActions( template,  _templates,_app );



   var sd=  await  getSuidTemplates(null,template?.structure,{})
setSuidTemplates(sd)
    // Recuperer les contents
  await data.getContents( (c=>(c.is_public ||  template.app_id+""===""+c.app_id  || c.owner_id===authUser.user_id ) ));
    app_id = template.app_id;

   
    await updateTestContent(_templateContent, _schemas);
   


  };


  // Écouteur pour la combinaison de touches Ctrl + Z
  useEffect(() => {
    const handleKeyUp = (event) => {
      // Vérifier si Ctrl (ou Cmd pour Mac) et Z sont pressés
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault(); // Empêche le comportement par défaut du navigateur
        undoing()
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {

      }
      
    };

const handleKeyDown=  function(event) {
  if ((event.ctrlKey || event.metaKey)  && event.key === 's') {
      event.preventDefault();
    
  
      event.preventDefault(); // Empêche le comportement par défaut du navigateur
      saving()   
      // Ici, tu peux ajouter ton propre comportement
  }
}

    document.addEventListener('keydown',handleKeyDown);
    // Ajouter l'écouteur d'événements au clavier
    window.addEventListener('keyup', handleKeyUp);



    // Nettoyage lors du démontage du composant
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown',handleKeyDown);
    };

  }, []);

  const loadAndRunActions = async (template,templates,app) => {
    var actions = GetActions(template,templates,app);

    await loadActions(actions);

    var onStartActions = actions.filter(
      (action) => action.Event === "onStart"
    );
    for (let startAction of onStartActions) {
      if (startAction.asynchrone)
        await runAction(
          null,
          startAction,
          internalContent,
          setInternal,
          true
        );
      else
        runActionSync(null, startAction, internalContent, setInternal, true);
    }
  };



  useEffect(() => {
    if (!template) return;
    var versions = sessionStorage.getItem("templateVersions_" + template_id);
    if (!versions) versions = [];
    else versions = JSON.parse(versions);

    var index = versions.findIndex(
      (t) => JSON.stringify(t) === JSON.stringify(template)
    );
    if (index !== -1) {
      if (index === versions.length - 1) return;
      versions = versions.filter((_, i) => i <= index);
      setTemplateVersions(versions);
      sessionStorage.setItem(
        "templateVersions_" + template_id,
        JSON.stringify(versions)
      );
      return;
    }
    versions.push(JSON.parse(JSON.stringify(template)));
    setTemplateVersions(versions);
    sessionStorage.setItem(
      "templateVersions_" + template_id,
      JSON.stringify(versions)
    );
  }, [template,template_id]);

  useEffect(() => {

    if (template && app_id && app_id !== template.app_id) {
      
      console.log("getAppData",{template, app_id})
      getAppData(template);
    }
  }, [template,template_id,getAppData]);

  const isEditable = useMemo(() => {
    return template && data.isGranted(authUser, template, "template", "update");
  }, [template,authUser]);


  const  initApp= async (template,app) => {
    var baseContent = {};
    if (app) {
      baseContent = {
        Titre: template.title,
        domain: app.domain,
        "App.Name": app.name,
        "App.Logo":
          process.env.REACT_APP_MFTC_SERVER_URL_FILES +
          `profiles/${app.domain}/user.png`,
        "App.Domain": app.domain,
        "App.Description": app.description,
        "App.primary_color": app.profile.primary_color,
        "App.secondary_color": app.profile.secondary_color,
        "App.login": `window.alert("Login!!")`,
        "App.logout": `window.alert("Logout")`,
        "App.goBack": `window.alert("navigate(-1)")`,
      };


      app.profile.appColor?.forEach((color,i)=> 
        {
      var index= i+1
          createClass(
            "bg-app-color-" + index,
            ` background-color :${color} ;  `
          );
          createClass(
            "border-app-color-"+index,
            ` border-color :${color} ;   `
          );
          createClass(
            "text-app-color-"+index,
            ` color :${color}  ;  `
          );
  
  
        }
      )

      createClass(
        "bg-primary_color",
        ` background-color :${app.profile.primary_color} ;  `
      );
      createClass(
        "border-primary_color",
        ` border-color :${app.profile.primary_color} ;   `
      );
      createClass(
        "text-primary_color",
        ` color :${app.profile.primary_color}  ;  `
      );
      createClass(
        "border-secondary_color",
        ` border-color :${app.profile.secondary_color} ;   `
      );
      createClass(
        "bg-secondary_color",
        ` background-color :${app.profile.secondary_color}  ;  `
      );
      createClass(
        "text-secondary_color",
        `color :${app.profile.secondary_color}  ;  `
      );
    }

    baseContent = {
      ...baseContent,
    //  ...setPropInputs (template, schemas, templates),
    };
    console.log("baseContent", { template, baseContent, templates, schemas });
    setBaseContent(baseContent);


  };
const migrateTemplate = async (template,templates) => {

  if(template.default_content_id ) return template

var content =  {}


const  detect=async (structure,pref="") => {
if(!structure.schema_prop && structure.schema_id &&  !structure.schema_label )
{
  content[  pref+  structure.suid ] = structure.default_value??structure.text??structure.imageUrl?? structure.icon
}
if(structure.template_id  )
  {
   var _t  = templates.find(t=>t.template_id+""===""+structure.template_id   ) 
   await  detect (_t.structure,structure.suid +"."  )
  }


 if(structure.children) structure.children.forEach(s=>detect(s))
}

//on liste 
await detect(template.structure)

if(Object.values(content).length>0)
  {
    if(!window.confirm("Migrer le template ? " + JSON.stringify(content))) return template
     var default_content = await data.save_content({ app_id:template.app_id,template_id:template.template_id,title:"Default Data/ " + template.title , content   })

      template.default_content_id= default_content.content_id;
      template =await data.save_template(template)
      window.alert("Migration du Template réussie")
     
  }
  return template
}
  const _setSelectedBloc = (selectedBloc) => {
    setSelectedBloc(selectedBloc);
  };

  const [schema_id, setSchema_id] = useState();
  useEffect(() => {
    if (template?.schema_id !== schema_id) {
      console.log("setSchema_id", template.schema_id, schema_id);
      setSchema_id(template.schema_id);
    }
  }, [template]);

  const globalSchema = useMemo(() => {
    if (schemas && schema_id) {
      // console.log("recalculate globalSchema ", schema_id,schemas)
      var r = schemas.find((s) => s.content_id === parseInt(schema_id));
      if (r) {
        r = {
          ...r,
          content: {
            ...r.content,
            schema: [
              { name: "Titre", realName: "title", schema_id: 42, code: "text" },
              ...(r.content.schema ?? []),
            ],
          },
        };
      }
      //    console.log("recalculate globalSchema ", schema_id,r)
      return r;
    }
  }, [schemas, schema_id]);
  const _setSelectedTemplate = (selectedTemplate) => {
    setSelectedTemplate(selectedTemplate);
  };
  const _setSelected = (selected) => {
    setSelected(selected);
  };

  const       canHaveChild = useMemo(()=>{
    if(selected && template)
    { var   _structure = getStructure(template.structure, selected);
    return  (!_structure.children || _structure.children.length === 0 ) && !_structure.template_id && (!_structure.schema_id || _structure.is_value)
    }
  },[selected,template]);

  const showWireframe = (show) => {
    if (show) {
      document.body.classList.add("wireframe");
    } else {
      document.body.classList.remove("wireframe");
    }
  };

  const selectTpl = (id, tpl, noEmit, focus) => {
    // console.log("selectTpl to", id, tpl);
    var realId = getIdStructure(template.structure, id);
 
id= realId
    var obj = document.getElementById(id);

    if (selected !== id) {
   //  var objFormer = document.getElementById(selected);
   //  if (objFormer) objFormer.classList.remove("selectedElement");
    }
    if(selected ===id){

      setIsMod(isMod && showTree ?true :true)
      setShowTree(isMod && showTree ?true :true)
        
    }

    if (obj ) {
     // obj.classList.add("selectedElement");
      if ( focus )
        obj.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
    }
    else {
     
     
    }
    // if (selected === id) return;
    if (tpl) {
      _setSelectedTemplate(tpl);
      if (tpl.schema_label) {
      }
    }

    _setSelected(id);
    setSelectingChoose(     )   
    if (!isMod && id) {
    
      var _structure = getStructure(template.structure, id);
      if (_structure.className?.startsWith("new ") && !isMod) {

        setSelectingChoose("addIn"    )        
        setShowProps(true)
       // setIsMod(true);
       // showModal("Brand New", {
       //   closeDelay: 5000,
       //   position: ModalPositions.RIGHTBOTTOM,
       // });
      }
    }

    //    console.log("userActives", userActives, users);
    if (userActives) {
      var _user = userActives.find((user) => user.session_id === session_id);
      if (_user && _user.selected !== id) {
        _user.selected = id;

        setUser(_user, null, true);
      }
    }

    if (!noEmit && id)
      data.emit({
        user: {
          selected: id,
          selectedBloc: id.split(".")[0] + "-edit",
          selectedTemplate: tpl,
        },
      }); //,
  };

  const setUser = (_user, quit, yesEmit) => {
    console.log("UserpINS Start", userActives, _user, session_id);
    var newusers = [];
    if (quit) {
      newusers = userActives.filter(
        (user, i) => user.session_id !== _user.session_id
      );
    } else {
      if (userActives.find((user) => user.session_id === _user.session_id))
        newusers = userActives.map((user, i) =>
          user.session_id === _user.session_id ? _user : user
        );
      else {
        newusers = [...userActives, _user];
        sendMsg({});
      }
      console.log("UserpINS Before", newusers);
    }
    userActives = newusers.map((user, i) => ({
      ...user,
      pos: newusers.filter(
        (user0, i0) => i0 < i && user.selected === user0.selected
      ).length,
    }));
    console.log("UserpINS ", userActives);

    setUsers(userActives);
  };

  const sendMsg = (props) => {
    var _user = userActives.find((user) => user.session_id === session_id);
    props = { user: _user, ...props, template_id };

    data.emit(props);
  };

  const self = useMemo(() => {
    if (!template?.schema_id || !schemas) {
      return {};
    }
    var schema = schemas.find(
      (s) => s.content_id === parseInt(template?.schema_id)
    );
    if (!schema) return null;
    var props = schema.content.schema;
    let result;
    if (schema.content.is_value) {
      result = {  ...templateContent , ...internalContent,}[
        "self"
      ];
    } else {
      result = { schema_id: template.schema_id };

      props?.forEach((val) => {
        result[val.name] = { ...templateContent, ...internalContent }[val.name];
      });
    }
    console.log("VIDANGE", {
      self: result,
      templateContent ,
      internalContent,
    });
    return JSON.stringify(result);
  }, [template, schemas, templateContent, internalContent]);

  const conditions = useMemo(() => {
    console.log("Init conditions");
    if (!template) return;
    var c = getConditions(template.structure);
    console.log("Nouvelles conditions", c);
    return c;
  }, [template]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    //  setBackground();
  }, []);

  useEffect(() => {
    console.log("template ", template);
    if (template && template["structure.json"]) {
      window.alert(
        "Le JSON Du template n'est pas controlable à corriger dans bdd !!!"
      );
      window.alert(template["structure.json"]);
      navigate("/templates");
      return;
    }
    if (template && !("children" in template.structure)) {
      window.alert("Structure mal formée :  absence de children");
      navigate("/templates");
      return;
    }
    if (!selected && template) selectTpl("-root", null, null, true);
  }, [template, selected]);

  const navigate = useNavigate();

  const captureTemplate = () => {
    selectTpl();
    setTimeout(() => {
      captureContent(
        "-root",
        `users/${template.owner_id}`,
        `template_${template_id}_thumb.png`,
        project
      );
    }, 20);
  };

  const dragging = (pos) => {
    if (pos.end) {
      setDragged();

      // C'est fini
    }
  };

  const cleanStructure = (structure, id) => {
    if ("children" in structure)
      structure.children = structure.children
        .filter((child) => {
          return !child.dragged;
        })
        .map((child, i) => {
          return cleanStructure(child, id + "." + i);
        });

    return structure;
  };

  const deleteTemplate = async () => {
    if (
      !window.confirm(
        "Are you sure you want to delete '" + template.title + "'?"
      )
    )
      return;
    await project.api("/delete_template/:template_id", {
      args: { template_id: template.template_id },
    });

    navigate("/templates");
  };

  const duplicateTemplate = async () => {
    var _template = JSON.parse(JSON.stringify(template));
    delete _template.template_id;
    _template.title = window.prompt(
      "Saisir le nom du nouveau template ",
      template.title + "-Copy"
    );
    if (!_template.title) return;
    await data.close();
    await data.init("template", "", false, project);
   delete    template.props_from_templates

    _template = await data.newTemplate(_template);



    await data.close();

    navigate(
      "/template/" + _template.template_id,
      "viewtpl" + _template.template_id,
      "menubar=no,toolbar=no,location=no,address=no,"
    );
  };
  const structure = useMemo(()=>{
    if(template?.structure)
    return getStructure(template?.structure,selected)
    
    },[template?.structure,selected])
  const addAfter = async (tpl, id, prop, structure) => {
    setSelectingChoose()
    setTemplateToGrab()

    var parts = id.split(".");
    var childId = parseInt(parts.pop());
    var parentId = parts.join(".");
    if (!parentId) {
      parentId = id;
      childId = 0;
    }
    if(tpl && structure) {
     var addTemplate  =  window.confirm("Ajouter le template en référence ?")

     if(addTemplate && addTemplate)
      {
        structure=null
      }
      else {
        tpl=null
      }
    }
    if (!structure) structure = tpl.structure;
    if(tpl?.meta_structure){

      structure=tpl.meta_structure
    }
else    if (prop) {
      structure = {
        template_id: tpl.template_id,
        className: " ",
        tag: "template",
        schema_id: prop.schema_id,
        isList: prop.type_nombre === "F" || prop.type_nombre === "I",
        listLength:
          prop.type_nombre === "F" || prop.type_nombre === "I" ? 2 : null,
        schema_prop: prop.name,
        children: [],
      };
    } else {
      if (tpl) {
        structure = {
          template_id: tpl.template_id,

          className: " ",
          tag: "template",
          schema_id: tpl.schema_id,
          isList: tpl.layout === "list",
          listLength: tpl.layout === "list" ? 2 : null,
          schema_label: tpl?.schema_id ? schemas.find(
            (s) => s.content_id + "" === "" + tpl.schema_id
          ).title:undefined,
          children: [],
        };
      }
    }
    var str = getStructure(template.structure, parentId);

    let children = [
      ...str.children.filter((child, i) => i <= childId),
      { children: [], ...structure },
      ...str.children.filter((child, i) => i > childId),
    ];

    console.log({ children });

    await updateTemplateStructure({ children }, parentId);
  };
  const insertStructure=async (id,direction)=>{
    var parts = id.split(".");
    var childId = parseInt(parts.pop());
    var parentId = parts.join(".");
    if (!parentId) {
      parentId = id;
      childId = 0;
    }
   var mainId
    var directions = { "top": { d:"v",i:0 ,pattern:"flex-col-reverse", className:"flex flex-col"   }, "left": { d:"h",i:0, pattern:"flex-row", className:"flex flex-row"   }, "right": { d:"h",i:1, pattern:"flex-row-reverse", className:"flex flex-row"  },"bottom":{d:"v",i:1, pattern:"flex-col", className:"flex flex-col"  ,}}

    var str = getStructure(template.structure, parentId);
    var classes =str.className.split(" ")
    var directionParent = "";

    if(classes.includes("flex")) {
     // "flex-row"
     directionParent = "right";

     for(let dir of  Object.keys(directions))
      {
          if(classes.includes(directions[dir].pattern))  {
            directionParent = dir;
          break;
          }
      }
    }
    var newStructure = { className:" p-2" ,children:[]}  
    var structure
  if(direction==="in")  
{
  str = getStructure(template.structure, id);

  let children = [
    ...str.children,
    newStructure
  ];
structure ={ ...str,children }
mainId = id;


}
  else  if(!directionParent ||  directions[directionParent].d!== directions[direction].d   )
{
  console.log(direction)
// On crée une structure qui encapsule  id et le nouveau 

  structure = {
    
    className:directions[direction].className ,children:[   
    ...(directions[direction].i===0 ?  [newStructure]:[]),
    getStructure(template.structure, id)  ,
    ...(directions[direction].i===1 ?  [newStructure]:[]),
    ]}
mainId= id

}
 else
 {



     str = getStructure(template.structure, parentId);
mainId =parentId
let j = ((directions[direction].i ===0 ? -1:1 )* (directions[directionParent].i ===0 ? -1:1)===-1?0:1)

    let children = [
      ...str.children.filter((child, i) => i < childId+j ),
      newStructure ,
      ...str.children.filter((child, i) => i>= childId+j),
    ];
    structure ={ ...str,children }
    mainId = parentId;
  }


    await updateTemplateStructure(structure,mainId,null,true);

  }
  
  const addIn= async ({tpl, prop, structure}) => {
    setSelectingChoose()
    setTemplateToGrab()

 var    id= selected

    if(tpl && structure) {
     var addTemplate  =  window.confirm("Ajouter le template en référence ?")

     if(addTemplate && addTemplate)
      {
        structure=null
      }
      else {
        tpl=null
      }
    }
    if (!structure) structure = tpl.structure;
    if (prop) {
      structure = {
        template_id: tpl.template_id,
        className: " ",
        tag: "template",
        schema_id: prop.schema_id,
        isList: prop.type_nombre === "F" || prop.type_nombre === "I",
        listLength:
          prop.type_nombre === "F" || prop.type_nombre === "I" ? 2 : null,
        schema_prop: prop.name,
        children: [],
      };
    } else {
      if (tpl) {
        structure = {
          template_id: tpl.template_id,

          className: " ",
          tag: "template",
          schema_id: tpl.schema_id,
          isList: tpl.layout === "list",
          listLength: tpl.layout === "list" ? 2 : null,
          schema_label: tpl?.schema_id ? schemas.find(
            (s) => s.content_id + "" === "" + tpl.schema_id
          ).title:undefined,
          children: [],
        };
      }
    }

    await updateTemplateStructure(structure, id);
  };


  const addFuncs = {addIn,addAfter  }


  const updateTemplateStructure = async (props, id, newSelected,replaceStructure) => {
    var newStructure = updateStructure(template.structure, props, id,replaceStructure);
    newStructure = suidize( [newStructure],{len:3,sub:"children"} )[0]

var _template=  {...template,
structure: newStructure,

}

_template =    await  computeProps(_template, templates, schemas );


setPropsTemplatesFrom(
  Object.values(  _template.props_from_templates[_template.template_id].props ).filter(p=> p.from!=="self"))

var dStructure = getStructure( _template.structure,id)

setStructureCss(dStructure,template_id ,true,null,cleanResponsiveClassName);
var sd = await  getSuidTemplates(dStructure.suid,dStructure,suidTemplates)

console.log(   { suid: dStructure.suid,structure: dStructure, sd, suidTemplates })
 setSuidTemplates({...sd});
    console.log({ newStructure,template });

    /*
    setTemplate({
      ...template,
      structure: newStructure,
    //  props_from_templates: propsFromTemplates,
    });
*/

    await data.updateTemplate(_template,setTemplate
     
    );
  
    if (newSelected)
      setTimeout(() => {
        selectTpl(newSelected);
      });
  };




  const getTemplateStructure = (id) => {
    return getStructure(template.structure, id);
  };

  const scrollIntoView = (id, block = "center") => {
    if (document.getElementById(id))
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block,
        inline: "start",
      });
  };

const [propsTemplatesFrom,setPropsTemplatesFrom] = useState()


const strActions= useMemo(()=>{

  const getStrActions=()=>{
  var result = []
  
  const getA= (structure,id)=>{
  
  
    structure.actions?.forEach((ea,index)=>
    {
      result.push({id: id, index,ea })
    })
  if(Array.isArray( structure.children))   structure.children.forEach((struct,i )=>{
  
      getA(struct, id+'.'+i   )
     })
  
  }

  getA(template.structure, "-root"  )
  
return result
  }
  
  if(template?.structure)
  return  getStrActions()
else return []
  
  },[template])

  const contentWithFuncs = useMemo(()=>{

    return {  self,
      ...baseContent,
      ...convertContent,
      ...internalContent,
      ...GetSetTers(template, null, null, { content: {} }),
      GetSetTers,}
    
    
    },[self,baseContent,convertContent,internalContent,template,GetSetTers])

  return (
    <div className="App  text-zinc-600 text-sm">
      <div
        className="fixed top-4 right-4 flex flex-row"
        style={{ zIndex: 60 }}
      ></div>
<div
        className="h-screen   fixed flex flex-col items-end justify-end top-0 left-0 p-0  "
        style={{ zIndex: 60 }}
      >
      <div
        className="safearea-bottom h-full bg-zinc-900 text-white flex flex-row items-start justify-start   p-2 rounded-2xl "
        style={{ zIndex: 62 }}
      >
        <div className="flex flex-col  items-start justify-start h-full p-2 gap-2">

         <ButtonTool
            icon={fa.faCodeBranch}
            onClick={() => setShowTree(!showTree)}
            onMouseOver={() => scrollIntoView("Hiérarchie du template")}
            active={showTree}
            label={"Hiérarchie du template"}
            side="right"
          />
                        {showTree && (
         <div  className="h-full overflow-y-auto">       <TemplateTree
                  selectTpl={selectTpl}
                  id={"-root"}
                  hue={0}
                  schemas={schemas}
                  selected={selected}
                  setDraggedZone={setDraggedZone}
                  draggedZone={draggedZone}
                  setDragOverZone={setDragOverZone}
                  dragOverZone={dragOverZone}
                  template={template?.structure ?? {}}
                  setSelected={selectTpl}
                  setStructure={(structure, parentStructure, dropped) => {
                    if (!isEditable) return;
                    if (dropped)
                      structure = cleanStructure(structure, "footer");
                    data.updateTemplate({ ...template, structure });
                  }}
                  dragged={dragged}
                  setDragged={(_id) =>
                    setDragged(_id, template.template_id, "-root")
                  }
                  dragging={dragging}
                  isEditable={isEditable}
                />        </div>
              )}
      
          <div className="flex flex-col ">

           {false && template && (
            <ButtonTool
              icon={fa.faAlignLeft}
              onMouseOver={() => scrollIntoView("ShowTemplateProps", "start")}
              onClick={() => setShowProps(!showProps)}
              active={showProps}
              label={"Propriétes"}
              side="right"
            />
          )}
          </div>
        </div>
        <div className="flex flex-col  items-end justify-end h-full overflow-hidden  ">
          <div
            className="    overflow-y-scroll bg-zinc-900 bg-opacity-10"
            style={{ height: "100vh" }}
          >
            <div className=" flex flex-col items-center justify-end  ">


              {showProps && (
                <ShowTemplateProps
                  id={"ShowTemplateProps"}
                  key={"ShowTemplateStructure" + selected}
                  content={{
                    self,
                    ...baseContent,
                    ...convertContent,
                    ...internalContent,
                    ...GetSetTers(template),
                    cleanResponsiveClassName,
                  }}
                  GetSetTersProps={GetSetTersProps}
                  setIsMod={setShowProps}
                  isMod={showProps}
                  selected={selected}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  addAfter={addAfter}
                  selectingChoose = {selectingChoose? addFuncs[selectingChoose] : setTemplateToGrab}
                />
              )}
            </div>
          </div>
        </div>
      </div></div>
      <div
        className=" text-white safearea-bottom fixed  flex flex-row items-center justify-center top-1 left-1/2 transform -translate-x-1/2   p-1 rounded-2xl"
        style={{ zIndex: 60 }}
      >
           <ButtonTool
          icon={fa.faDesktop}
          side="bottom"
          label={"Version Desktop"}
          onClick={() => {
            setShow((show) => ({ ...show, Desktop: !show.Desktop }));
          }}
          active={show.Desktop}
          nolabelActive={true}
        />
        <ButtonTool
          icon={fa.faMinus}
          onClick={() => setScale(scale * 0.9)}
          onMouseOver={() => setScale(scale * 0.98)}
          side="bottom"
          label={"Dézoomer"}
        />
        <div className="flex flex-col items-center justify-start ">
          {" "}
          <ButtonTool
            icon={fa.faExpand}
            onClick={() => {
              setScale(0.9);
              selectTpl("-root");
            }}
            side="bottom"
            label={"Center Grandeur réelle "}
          />
          <div className="text-sm text-white ">{Math.round(scale * 100)} %</div>
        </div>
        <ButtonTool
          icon={fa.faPlus}
          onClick={() => setScale(scale / 0.9)}
          onMouseOver={() => setScale(scale / 0.98)}
          side="bottom"
          label={"Zoomer"}
        />
        <ButtonTool
          icon={fa.faTableCells}
          side="bottom"
          label={" Wireframe"}
          onClick={() => {
            showWireframe(!show.Wireframe);
            setShow((show) => ({ ...show, Wireframe: !show.Wireframe }));
          }}
          active={show.Wireframe}
          nolabelActive={true}
        />
      </div>
     <ContextToolManager    content={baseContent}
                  setShow={setShow}
                  showStyle={show.Code}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  ComponentStructTools={ComponentStructTools}
                  key_schemas={key_schemas}  
                   isEditable={isEditable}     scrollIntoView={scrollIntoView}    show={show} selectTpl={selectTpl}  
                  app={app}
                  updateApp={async (app)=>{ await initApp(template,app);setApp(app) }} 
                  templateContent ={templateContent} updateContent={updateTestContent}
                   
                    mq={   {mediaQueries,mediaQuery,changeMediaQuery  } }
    suidTemplates={suidTemplates} 
                  
                   propsTemplates={ propsTemplatesFrom }
                  
                   />
      <div 
        className="h-screen   fixed flex flex-col items-end justify-end top-0 right-0 p-0  "
        style={{ zIndex: 60 }}
      >
         <div
        className="bg-zinc-900 text-white h-full  flex flex-col items-end justify-end p-2 py-4 overflow-hidden rounded-2xl "
        style={{ zIndex: 60 }}
      >
        <div className="flex flex-row items-end justify-end h-full  overflow-hidden ">
          <div className=" p-1  h-full overflow-y-scroll ">
            <div className=" flex flex-col items-center justify-end min-h-full pt-14  ">
              {show.Tag && (
                <ShowTagStructure
                  key={"ShowTagStructure" + selected}
                  content={baseContent}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  ComponentStructTools={ComponentStructTools}
                  key_schemas={key_schemas}
                />
              )}
              {isMod && selected && (
                <ShowTemplateStructure
                  key={"ShowTemplateStructure" + selected}
                  content={baseContent}
                  setIsMod={setIsMod}
                  isMod={isMod}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  templateContent={templateContent}
                  updateContent={updateTestContent}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  ComponentStructTools={ComponentStructTools}
                  key_schemas={key_schemas}
                />
              )}

              {show.Code && (
                <ShowComponentTools
                  key={"ShowComponentTools" + selected}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  ComponentTools={ComponentTools}
                  selectTpl={selectTpl}
                  key_schemas={key_schemas}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  context= { {template}}
                />
              )}
              {show.Event && (
                <ShowTemplateEvent
                  key={"ShowTemplateEvent" }
                  content={baseContent}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  key_schemas={key_schemas}
                  context={ {template}  }
                />
              )}
              {show.EventAction && (
                <ShowTemplateEventActions
                  key={"ShowTemplateEventAction" }
                  strActions={strActions}
                  content={baseContent}
                  setShow={setShow}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  key_schemas={key_schemas}
                  context={ {template}  }
                  selectTpl={selectTpl}
                />
              )}
              {show.Gear && (
                <TemplateParams
                  key={"TemplateParams" + selected}
                  template={template}
                  setShow={setShow}
                  schemas={schemas}
                  
                  roles={roles}
                  conditions={conditions}
                  internalContent={internalContent}
                  setInternalContent={setInternalContent}
                  deleteTemplate={
                    data.isGranted(authUser, template, "template", "delete") &&
                    deleteTemplate
                  }
                  key_schemas={key_schemas}
                />
              )}
                            {show.Props && (
                <TemplateProps
                  key={"TemplateProps" + selected}
                  template={template}
                  setShow={setShow}
                  schemas={schemas}
                
                  roles={roles}
                  conditions={conditions}
                  internalContent={internalContent}
                  setInternalContent={setInternalContent}
               
                  key_schemas={key_schemas}
                />
              )}
                                          {show.Meta && (
                <TemplateMeta
                  key={"TemplateMeta" }
                  template={template}
                  setShow={setShow}
                  schemas={schemas}
                
                  roles={roles}
                  conditions={conditions}
                  internalContent={internalContent}
                  setInternalContent={setInternalContent}
               
                  key_schemas={key_schemas}
                />
              )}
                            {show.DataSource &&  (
                <ShowDataSource
                 
                  content={contentWithFuncs}
                  setShow={setShow}
               app_id={templateContent?.app_id}
                  template={template}
                  updateTemplateStructure={updateTemplateStructure}
                  selected={selected}
                  templates={templates}
                  schemas={schemas}
                  globalSchema={globalSchema}
                  ComponentStructTools={ComponentStructTools}
                  key_schemas={key_schemas}
                />
              )}
              {show.Data && (
                <ShowTestingContent
                  setShow={setShow}
                  schemas={schemas}
                  changePropContent={changePropContent}
                  suidTemplates={suidTemplates}
                  testContent={templateContent}
                  conditions={conditions}
                  internalContent={internalContent}
                  setInternalContent={setInternalContent}
                  setInternal={setInternal}
                  updateTestContent={updateTestContent}
                  propsTemplates={ propsTemplatesFrom }
                  infos={{
                    self,
                    ...baseContent,
                    ...convertContent,
                    ...internalContent,
                    ...GetSetTers(template),
                    GetSetTers,
                  }}
                  GetActions={GetActions}
                  key_schemas={key_schemas}
                  template={template}
                />
              )}
              {show.localContent && (
                <ShowLocalContent
                  setShow={setShow}
                  localContent={localContent}
                  structure={template.structure}
                  selectTpl={selectTpl}
                  key_schemas={key_schemas}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col items-end justify-end relative">
            {isEditable && selected && (
              <>
                <ButtonTool
                  onMouseOver={() => scrollIntoView("ShowTemplateStructure")}
                  icon={fa.faPen}
                  onClick={() => setIsMod(!isMod)}
                  active={isMod}
                  label={"Mode Édition"}
                />

                <ButtonTool
                  onMouseOver={() => scrollIntoView("ShowTagStructure")}
                  icon={fa.faHashtag}
                  onClick={() => {
                    setShow((show) => ({ ...show, Tag: !show.Tag }));
                  }}
                  active={show.Tag}
                  label={"Tag Attributes"}
                />

    
  

                <div   className="flex relative "><ButtonTool
                  icon={fa.faPlay}
                  onMouseOver={() => scrollIntoView("ShowTemplateEvent")}
                  onClick={() => {
                    setShow((show) => ({ ...show, Event: !show.Event }));
                  }}
                  active={show.Event}
                  label={"Click / Lien / Evènements"}
                />
                {  structure.actions?.length>0 &&  <div className="z-20 absolute  right-0 top-0 flex justify-center items-center rounded-full text-xs w-4 h-4 bg-red-500 text-white">
                  { structure.actions?.length}</div>
                  }
                </div>

                <div className="w-12 border-t border-white m-1 p-2 border-opacity-40 mb-10"></div>
              </>
            )}

            {isEditable && (
              <ButtonTool
                onMouseOver={() => scrollIntoView("TemplateParams")}
                icon={fa.faGear}
                onClick={() => {
                  setShow((show) => ({ ...show, Gear: !show.Gear }));
                }}
                active={show.Gear}
                label={"Paramètres du template"}
              />
            )}
                        {isEditable && (
              <ButtonTool
                onMouseOver={() => scrollIntoView("TemplateProps")}
                icon={fa.faGears}
                onClick={() => {
                  setShow((show) => ({ ...show, Props: !show.Props }));
                }}
                active={show.Props}
                label={"Propriétés du template"}
              />
            )}

{ strActions.length>0 &&
    <div   className="flex relative "><ButtonTool
    icon={fa.faPlay}
    onMouseOver={() => scrollIntoView("ShowTemplateEventAction")}
    onClick={() => {
      setShow((show) => ({ ...show, EventAction: !show.EventAction }));
    }}
    active={show.EventAction}
    label={"Actions template"}
  />
  <div className="z-20 absolute  right-0 top-0 flex justify-center items-center rounded-full text-xs w-4 h-4 bg-red-500 text-white">
    { strActions.length}</div>
  </div>
}

                                    {   Object.values( template?.meta??{}).length>0  && isEditable && (
              <ButtonTool
                onMouseOver={() => scrollIntoView("TemplateMeta")}
                icon={fa.faSpinner}
                onClick={() => {
                  setShow((show) => ({ ...show, Meta: !show.Meta }));
                }}
                active={show.Meta}
                label={"Meta du template"}
              />
            )}
                        {  (
              <ButtonTool
                onMouseOver={() => scrollIntoView("ShowDataSource")}
                icon={fa.faDatabase}
                onClick={() => {
                  setShow((show) => ({ ...show, DataSource: !show.DataSource }));
                }}
                active={show.DataSource}
                label={"Data Source"}
              />
            )}
            {templateContent &&  (
              <ButtonTool
                onMouseOver={() => scrollIntoView("ShowTestingContent")}
                icon={fa.faNoteSticky}
                onClick={() => {
                  setShow((show) => ({ ...show, Data: !show.Data }));
                }}
                active={show.Data}
                label={"Données Test"}
              />
            )}
            {localContent && (
              <ButtonTool
                onMouseOver={() => scrollIntoView("ShowLocalContent")}
                icon={fa.faBug}
                onClick={() => {
                  setShow((show) => ({
                    ...show,
                    localContent: !show.localContent,
                  }));
                }}
                active={show.localContent}
                label={"Données Element"}
              />
            )}

            <ButtonTool
              icon={fa.faPaste}
              onClick={duplicateTemplate}
              label={"Dupliquer le template "}
              inactiveClass=" bg-green-500 text-white  bg-opacity-100 "
            />

          </div>
        </div>
        <div className="flex flex-row ">
          {
            <>
              {isEditable && Object.values(saveAll).length > 0 && (
                <>
                  {templateVersions && templateVersions.length - 1 > 0 && (
                    <ButtonTool
                      icon={fa.faChevronLeft}
                      onClick={undoing}
                      defaultClass=" text-red-600  bg-black border-red-600 "
                      inactiveClass=" text-red-600  bg-black border-red-600"
                      side="top"
                      label={"Annuler l'action précédente"}
                      badge={templateVersions && templateVersions.length - 1}
                    />
                  )}
                  <ButtonTool
                    icon={fa.faAngleDoubleLeft}
                    onClick={cancelling}
                    defaultClass=" text-red-600  bg-black border-red-600 "
                    inactiveClass=" text-red-600  bg-black border-red-600"
                    side="top"
                    label={"Abandonner le brouillon"}
                  />
                  <ButtonTool
                    icon={fa.faCheck}
                    onClick={saving}
                    side="top"
                    defaultClass="text-green-600  bg-black border-green-600 "
                    inactiveClass=" text-green-600 bg-black border-green-600 "
                    label={"Enregistrer les modifications"}
                  />
                </>
              )}
            </>
          }
          <ButtonTool
            icon={fa.faClose}
            onClick={() => {
                  // Vérifie s'il y a au moins une page dans l'historique
    if (window.history.length > 1) {
      navigate(-1); // Retourne à la page précédente
    } else {
      window.close(); // Ferme la fenêtre si aucune page précédente
    }
             
            }}
            side="topleft"
            label={"Fermer le template"}
          />
        </div>
      </div>
      </div>
      {project && template && conditions && (
        <header className=" relative w-screen h-screen overflow-scroll ">
          <div
            className="z-1 w-full  flex flex-col items-center p-4 min-h-screen justify-center transition-all relative"
            id="template-content"
            style={{
              transform: `scale(${scale})`,
              width: "200vw",
              height: "200vh",
            }}
          >
            <div className="relative h-screen w-screen  flex flex-col items-center justify-center ">
              <div   id={"parentScreener"}  className="flex flex-row items-start relative">

              <div  className="flex flex-col justify-end items-end relative">

           <div className="flex flex-row"
           >
            <div className="bg-white "></div> <ButtonTool
              icon={ fa.faHand}
              onClick={() => {
                
               if(selectingChoose || templateToGrab) 
                {
                setSelectingChoose()
                setTemplateToGrab()
       
                }
                else{
                  if(selected)
                    {
                      
                      setSelectingChoose("addIn"      )        
                      setShowProps(true)
                    }
                }



              }}
              active={ !!(selectingChoose || templateToGrab)}
              defaultClass=" bg-red-500"
              label={"Annuler le grab "}
            /></div>


            {isEditable && (
              <ButtonTool
                icon={fa.faPhotoFilm}
                onClick={captureTemplate}
                label={"Screenshot Template"}
                inactiveClass="  bg-yellow-500 text-white  bg-opacity-100 "
              />)}
              <ButtonTool
              icon={show.showScroll ? fa.faUpDown : fa.faUpDown}
              onClick={() => {
                setShow((show) => ({
                  ...show,
                  showScroll: !show.showScroll,
                }));
              }}
              active={show.showScroll}
              label={"Scrolling Activé"}
            />
</div>
<div className="flex flex-col gap-2">
              <div   id="parentFrame"  className={" relative bg-zinc-900  items-center justify-center  border-black pt-2 pb-4 p-2 box-content gap-2 rounded-xl "    }>
              <InputSelect 
        className={"text-white m-2 bg-zinc-900"}
      classOption={"text-white bg-zinc-900"}
        label={""}  value={ ScreenSizes.find(s=> JSON.stringify( s.style   )===JSON.stringify(ScreenSize) ).deviceName }    onChange={ (deviceName)=>changeDevice(deviceName) }  options={ScreenSizes.map(s=>({label:s.deviceName,value:s.deviceName }))   }  />
    
              <div id={"parentScreen"} className={ "relative bg-white " +  ( show.showScroll? "":" flex flex-row justify-center items-center justify-start  "  )  }     style={{...ScreenSize, ...(show.showScroll? {height:ScreenSize.minHeight,overflowY:"auto"}:{})   }} >
                {template && (
                  <TemplateX
                    template={{ ...template.structure  } }
                    setInternal={setInternal}
                    setLocalContent={setLocalContent}
                    content={{simulatingScroll,
                    ...contentWithFuncs,
                      updateTemplateStructure,
                      getTemplateStructure,
                      dynamicsProps: {
                        onDragOver: (e) => {
                          //   e.stopPropagation();
                          e.preventDefault();
                        },
                        onDrop: async (e) => {
                          e.target.classList.remove(
                            "border-green-500",
                            "border-4",
                            "scale-2"
                          );
                          //  console.log(e.target)
                          var elt = e.target;

                          while (elt && !elt.id) elt = elt.parentNode;
                          var id = elt.id;
                          if (!id) return;

                          // e.preventDefault();
                          e.stopPropagation();
                          const data =
                            e.dataTransfer.getData("application/json");
                          console.log(data);
                          //   window.alert( e.target.id +  " " + template.schema_prop  +" : " +data)
                          try{
                          var { tpl, prop, structure } = JSON.parse(data);
                          } catch(e){
                            console.log(e)
                            return
                          }
                          await addAfter(tpl, id, prop, structure);

                          //   window.alert(parentId +" " + JSON.stringify( children))
                        },
                        onDragEnter: (e) => {
                          e.target.classList.add(
                            "border-green-500",
                            "border-4",
                            "scale-2"
                          );
                        },
                        onDragLeave: (e) => {
                          e.target.classList.remove(
                            "border-green-500",
                            "border-4",
                            "scale-2"
                          );
                        },
                        onMouseDown: (e) => {
                        
                       if(templateToGrab)   positionToGrab(e)

                          
                        },
                        onMouseUp: (e) => {


                        },
                      },
                      showHidden: show.showHidden,
                      cleanResponsiveClassName,
                      
                    }}
                    templates={templates}
                    selectTpl={selectTpl}
                    id={"-root"}
                    selected={selected}
                    safeDisplay={true}
                    tpl_id = {template?.template_id}
                  />
                 
                )}
              
   </div>
           <div className="p-2 text-white">{template?.title}</div> 
            </div>
            <div className="relative grow  bg-zinc-900 rounded-full cursor-move h-6 m-2 flex items-center justify-center"  onClick={(e)=>{
  const div = e.currentTarget;
  const rect = div.getBoundingClientRect();
const parentScreen = document.getElementById("parentScreen")
const prect = parentScreen.getBoundingClientRect();

  // Position du clic dans le div
  const clickX = e.clientX;
const width = rect.width/3
  // Distance entre le clic et le haut du div
  const perc =Math.max(-100, Math.min(100,-50+ 100*(clickX - rect.left-width)/width ));

 
setSimulatingScroll(   {...simulatingScroll, percx:perc, id:"simulatingScroll" ,left: perc*prect.width /100   ,  right: (1+perc/100)*prect.width, height: prect.height, width: prect.width }  );
}}>
  <div className="w-1/3 bg-zinc-500 h-2 rounded-full "></div>
  <div className={ "absolute top-1 border w-1/3  border-white h-4 rounded-full transition-all duration-1000"  }    style={{   left:`${ (simulatingScroll.percx +100)/3  }%` }}></div>
</div>
</div>




            <Frame  id={selected} parent={"parentScreener"} 
                
                
                insert={  show.Plus &&  ( (direction)=>insertStructure(selected,direction))    }
                insertInside={ show.Plus && canHaveChild ?   ()=>insertStructure(selected, "in") :null}
                />
           
<div className="relative flex flex-col h-full">
<ButtonTool
              icon={show.showHidden ? fa.faEye : fa.faEyeSlash}
              onClick={() => {
                setShow((show) => ({
                  ...show,
                  showHidden: !show.showHidden,
                }));
              }}
              active={show.showHidden}
              label={"Afficher les objets masqués"}
            />



            {isEditable && (
              <ButtonTool
                icon={fa.faPhotoFilm}
                onClick={captureTemplate}
                label={"Scrolling"}
                inactiveClass="  bg-yellow-500 text-white  bg-opacity-100 "
              />)}
              <ButtonTool
              icon={show.showScroll ? fa.faUpDown : fa.faUpDown}
              onClick={() => {
                setShow((show) => ({
                  ...show,
                  showScroll: !show.showScroll,
                }));
              }}
              active={show.showScroll}
              label={"Scrolling Activé"}
            />
<div className="relative grow  bg-zinc-900 rounded-full cursor-move w-6 m-2 flex items-center justify-center"  onClick={(e)=>{
  const div = e.currentTarget;
  const rect = div.getBoundingClientRect();
const parentScreen = document.getElementById("parentScreen")
const prect = parentScreen.getBoundingClientRect();

  // Position du clic dans le div
  const clickY = e.clientY;
const height = rect.height/3
  // Distance entre le clic et le haut du div
  const perc =Math.max(-100, Math.min(100,-50+ 100*(clickY - rect.top-height)/height ));

 




setSimulatingScroll(   { ...simulatingScroll, perc, id:"simulatingScroll" ,top: perc*prect.height /100   , bottom: (1+perc/100)*prect.height, height: prect.height, width: prect.width }  );
}}>
  <div className="h-1/3 bg-zinc-500 w-2 rounded-full "></div>
  <div className={ "absolute left-1 border h-1/3  border-white w-4 rounded-full transition-all duration-1000"  }    style={{   top:`${ (simulatingScroll.perc +100)/3  }%` }}></div>
</div>



</div>

            </div>
          
            </div>
            {users.map((user) => (
              <UserPin
              scale={scale}
                key={user.session_id}
                id={user.selected}
                user={user}
                root_id={"template-content"}
              />
            ))}
              
          </div>
        </header>
      )}
    </div>
  );
}

export default SetTemplate;
