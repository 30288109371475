import React, { useEffect, useMemo, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure, optionsArgDispo } from "../../../../../tools/tpl";
import SelectDropDown from "../../../../../components/inputs/SelectDropDown";
import useDynamicTextColor from "../../../../../tools/useDynamicTextColor";
import InputText from "../../../../../components/inputs/InputText";
import InputData from "../../../../../components/inputs/InputData";


const categories = [
  { 
    name: "Lien Page", 
    key:"link"
    ,nb: (str)=>(str.schema_link?1:0)
  },
  { 
    name: "Lien Externe", 
    key:"url"
    ,nb: (str)=>(str.schema_url?1:0)
  },
  { 
    name: "Evenement/Action", 
key:"event"
,nb: (str)=>(str.actions?.length??0)
  },
  { 
    name: "Activation ", 
key:"activation"
,nb: (str)=> (str.actions?.length??0)
  },
  { 
    name: "Calcul", 
key:"compute"
,nb: (str)=>  (str.computed?.length??0)
  },
  { 
    name: "UI", 
key:"ui"
,nb: (str)=> (str.vars?.length??0)
  },
];


const ContextToolActions = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app,key_schemas,updateContent,templateContent}) => {
  


const   genDefaultOptionRender=(schema_id)=>
{
const DefaultOptionRender = ({value,fieldSearch,onClick }) =>{
  const elementRef = useRef(null);
  const [newProp,setNewProp]=useState()
  const {textColor,backgroundColor } = useDynamicTextColor(elementRef);

  const addNewProp=async ()=>{
     
    onClick({value:newProp})
  }
  if( value.new){

    return <div className=" flex w-full  items-center gap-2 text-left  text-gray-500 border-gray-500  border-opacity-25 " ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}>
      <InputText className={"w-full"} value={newProp}    onChange={setNewProp }     />

    <FontAwesomeIcon  icon={fa.faPlus}    className="w-4 h-4 p-1 rounded-full bg-green-800 text-white "  onClick={addNewProp} /></div>

  }
  return <div className="text-left p-2 text-gray-500 border-gray-500 border-t border-opacity-25 " ref={elementRef}  onClick={()=>onClick(value)} stylea={{color:textColor,backgroundColor:backgroundColor}}>{ (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )} </div>
}
return DefaultOptionRender
}




  

    const [activeCategory, setActiveCategory] = useState(categories[0].name);

    const  keyActive = useMemo(()=>{
return categories.find(c=>c.name===activeCategory)?.key

    },[activeCategory])
  if (!selected) return <></>;
  if(!selected ) return <></>  
  return (
    <div
    id={"ShowTemplateStructure"}
    className="bg-zinc-900 text-white pb-2 px-2 rounded-xl m-2 flex-inline flex-col items-center justify-center  border-white shadow text-xs  relative"
    style={{maxHeight:"50vh",overflow:"auto"}}
  >
         {/* Onglets */}
         <div className="bg-zinc-900 flex p-2 gap-4 mb-4 sticky top-0 z-50">
      {categories.map((category) => (
        <div
          key={category.name}
          onClick={() => setActiveCategory(category.name)}
          className={"flex relative " +(category.subname ? 'px-2 ': 'px-2 '     )+ `  cursor-pointer  py-2 rounded ${
            activeCategory === category.name
              ? "bg-green-700 text-white "
              : (category.subname ? "bg-zinc-800 text-gray-300":"bg-zinc-700 text-gray-300" )
          }`}
        >
          {category.subname??  category.name}
          { category.nb && category.nb(structure)>0  &&<div className="z-20 absolute  -right-2 -top-2 flex justify-center items-center rounded-full text-xs w-4 h-4 bg-red-500 text-white">
            { category.nb(structure)}</div>}
        </div>

      ))}
    </div>

{  
keyActive==="link" && <div className="w-full flex ">
<SelectDropDown
             id={"pagelink "+selected}
           className={"w-full"}
             label={" Page Link" }
             value={ structure.schema_link}
             options= {
          [...optionsArgDispo             ( {  schema_id: "46" },{template,schemas},schemas)
          ,{  value:"",new:true  }]
             }
             fieldId={"value"}
             fieldSearch="value"
             onChange={(result) =>{
                         if(result.value   === structure.schema_link)result.value=null
              updateTemplateStructure( {...structure, schema_link:result.value     },selected)
             }
            }
            internalClick={true}
            placeholder={"Page Link"}
OptionRender={genDefaultOptionRender( 46  ) }
            />
                        {
structure.schema_link && 
<InputData    className={"w-full"} prop={  { name:"défaut -" +structure.schema_link, type_nombre:template.isList? "I":null, schema_id: template.schema_id }}  value={templateContent.content[structure.schema_link] }  schema={schemas.find(s=>s.content_id+""==="46")} onChange={(default_value) => {
updateContent({...templateContent,content:{...templateContent.content,[structure.schema_link]:default_value}});

  }}    context={ {app_id:template.app_id   ,template,schemas }   }   />


            }
</div>
}
{  
keyActive==="activation" && <div className="w-full flex ">
<SelectDropDown
             id={"propactive "+selected}
           className={"w-full"}
             label={" Propriété d'activation" }
             value={ structure.activeProp}
             options= {
          [...optionsArgDispo             ( {  },{template,schemas},schemas)
          ,{  value:"",new:true,schema_id:42  }]
             }
             fieldId={"value"}
             fieldSearch="value"
             onChange={(result) =>{
                         if(result.value   === structure.schema_link)result.value=null
              updateTemplateStructure( {...structure, activeProp:result.value     },selected)
             }
            }
            internalClick={true}
            placeholder={"Propriété d'activation"}
OptionRender={genDefaultOptionRender( 42  ) }
            />

</div>
}
{  
keyActive==="url" &&  <div className="w-full flex ">
<SelectDropDown
             id={"pagelink "+selected}
             className={"w-full"}
             label={" Url Link" }
             value={ structure.schema_url}
             options= {
             [...optionsArgDispo             ( {  schema_id: "466" },{template,schemas},schemas)
              ,{  value:"",new:true  }]
             }
             fieldId={"value"}
             fieldSearch="value"
             onChange={(result) =>{
                         if(result.value   === structure.schema_url)result.value=null
              updateTemplateStructure( {...structure, schema_url:result.value     },selected)
             }
           
            }
            internalClick={true}
            placeholder={" Url Link"}
            OptionRender={genDefaultOptionRender( 466  ) }
            />
            {
structure.schema_url && 
<InputData    className={"w-full"} prop={  { name:"défaut -" +structure.schema_url, type_nombre:template.isList? "I":null, schema_id: structure.schema_id }}  value={templateContent.content[structure.schema_url] }  schema={schemas.find(s=>s.content_id+""==="466")} onChange={(default_value) => {
updateContent({...templateContent,content:{...templateContent.content,[structure.schema_url]:default_value}});

  }}  />


            }
</div>
}
{

  keyActive==="event" && 
<><InputData
                                id={selected + "_event_"}
                  className={"w-full"}
                  prop={{ name: "Event Action", type_nombre: "I", define: true,define_open:true  }}
                  schema={{ content_id: 440 }}
                  value={structure.actions ?? []}
                  onChange={(actions) => updateTemplateStructure({ ...structure, actions,  },selected)}
                  context={{showList:true,template,schemas}}
                />
</>}

{
  keyActive==="compute" && 
<>
<InputData
                id={selected + "_computed_"}
                  className={"w-full"}
                  prop={{ name: "Propriété Calculée", type_nombre: "I", define: true,define_open:true  }}
                  schema={{ content_id: 653 ,content:{code:"computed"   }}}
                  value={structure.computed ?? []}
                  onChange={(computed) => updateTemplateStructure({ ...structure, computed },selected)}
                  context={{ showList:true, app_id:template.app_id,
               key_schemas : key_schemas    }}
            
                />


</>}

{
  keyActive==="ui" && 
<>

                <InputData
                  className={"w-full"}
                  prop={{ name: "Variable UI", type_nombre: "I", define: true,define_open:true }}
                  schema={{ content_id: 137 }}
                  value={structure.vars ?? []}
                  onChange={(vars) => updateTemplateStructure({ ...structure, vars },selected)}
                  context={{showList:true}}
                />
</>}


    </div>
  );
};

export default ContextToolActions;
